const Refer = () => {
  return (
    <div className="refer-details">
      <div>
        <div>
          <img src="/logorefer.png" alt="logo" />
        </div>
        <div>
          <h3>Flat off on Women’s Cloth</h3>
          <p>Offer valid on order Above ₹299.00</p>
          <h3>₹ 120.00</h3>
          <button type="button">Refer</button>
        </div>
      </div>
      <div>
        <div>
          <img src="/logorefer.png" alt="logo" />
        </div>
        <div>
          <h3>Flat off on Women’s Cloth</h3>
          <p>Offer valid on order Above ₹299.00</p>
          <h3>₹ 120.00</h3>
          <button type="button">Refer</button>
        </div>
      </div>
      <div>
        <div>
          <img src="/logorefer.png" alt="logo" />
        </div>
        <div>
          <h3>Flat off on Women’s Cloth</h3>
          <p>Offer valid on order Above ₹299.00</p>
          <h3>₹ 120.00</h3>
          <button type="button">Refer</button>
        </div>
      </div>
    </div>
  );
};
export default Refer;
